import * as React from 'react';

import { SVGProps } from '../../types';

export const IconCheckmarkSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.306 7.28a1 1 0 011.473 1.347l-.085.093-9.333 9a1 1 0 01-1.295.08l-.093-.08-4.667-4.5a1 1 0 011.292-1.521l.096.081 3.972 3.831 8.64-8.33z" />
  </svg>
);
