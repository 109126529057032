import * as React from 'react';

import { SVGProps } from '../../types';

export const IconPauseSvg = ({
  title,
  titleId,
  className,
  ...props
}: SVGProps) => (
  <div className={className + ' leading-none'}>
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      focusable="false"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <rect
        x="5.75"
        y="4.75"
        width="4.5"
        height="14.5"
        rx="1.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <rect
        x="14.75"
        y="4.75"
        width="4.5"
        height="14.5"
        rx="1.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  </div>
);
