import * as React from 'react';

import { SVGProps } from '../../types';

export const IconEnvelopeSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.25 4A2.75 2.75 0 0122 6.75v10.5A2.75 2.75 0 0119.25 20H4.75A2.75 2.75 0 012 17.25V6.75A2.75 2.75 0 014.75 4zm-4.393 9.096l-1.046.916a2.75 2.75 0 01-3.477.118l-.145-.118-1.046-.916-5.47 4.788c.196.334.544.568.95.61l.127.006h14.5c.46 0 .86-.248 1.078-.616l-5.471-4.788zM20.5 8.158L15.995 12.1l4.505 3.941V8.158zm-17 .001v7.881l4.504-3.94L3.5 8.159zM19.25 5.5H4.75a1.25 1.25 0 00-1.148.755l7.575 6.628a1.25 1.25 0 001.533.088l.113-.088 7.575-6.628A1.25 1.25 0 0019.25 5.5z" />
  </svg>
);
