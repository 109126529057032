import * as React from 'react';

import { SVGProps } from '../../types';

export const IconFinishSvg = ({
  title,
  titleId,
  className,
  ...props
}: SVGProps) => (
  <div className={className + ' leading-none'}>
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      focusable="false"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M15 1H3.5C2.39543 1 1.5 1.89543 1.5 3V15C1.5 16.1046 2.39543 17 3.5 17H15.5C16.6046 17 17.5 16.1046 17.5 15V9.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M6.5 8.30769L9.73529 12L17.5 4"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);
