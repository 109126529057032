import * as React from 'react';

import { SVGProps } from '../../types';

export const IconExternalSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.964 4a.75.75 0 01.102 1.493l-.102.007H6.75a1.25 1.25 0 00-1.244 1.122L5.5 6.75v10.5c0 .647.492 1.18 1.122 1.244l.128.006h10.5a1.25 1.25 0 001.244-1.122l.006-.128v-4.214a.75.75 0 011.493-.102l.007.102v4.214a2.75 2.75 0 01-2.582 2.745L17.25 20H6.75a2.75 2.75 0 01-2.745-2.582L4 17.25V6.75a2.75 2.75 0 012.582-2.745L6.75 4h4.214zM21.25 2h.017c.02 0 .04.002.06.004L21.25 2a.754.754 0 01.601.301l-.07-.081a.753.753 0 01.212.428L22 2.75v6a.75.75 0 01-1.493.102L20.5 8.75V4.56l-8.97 8.97a.75.75 0 01-1.133-.976l.073-.084 8.968-8.97H15.25a.75.75 0 01-.743-.648L14.5 2.75a.75.75 0 01.648-.743L15.25 2h6z" />
  </svg>
);
