import * as React from 'react';

import { SVGProps } from '../../types';

export const IconShareSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18 2.25a3.75 3.75 0 11-3.198 5.709L9.55 10.787c.13.38.2.789.2 1.213 0 .568-.126 1.107-.352 1.59l5.384 2.484a3.75 3.75 0 11-.498 1.421l-5.807-2.68a3.75 3.75 0 11.312-5.323l5.498-2.96A3.75 3.75 0 0118 2.25zm0 13.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-12-6a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm12-6a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
  </svg>
);
