import * as React from 'react';

import { SVGProps } from '../../types';

export const IconPencilSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.667 3.024c.42 0 .8.174 1.072.454l1.76 1.765a1.49 1.49 0 01-.027 2.255l-11.96 11.99.007.005-4.569 1.503c-.323.024-.565-.046-.727-.208-.162-.163-.236-.423-.223-.781l1.503-4.516.003.002L16.522 3.558a1.49 1.49 0 011.145-.534zM5.669 16.735l-.79 2.376 2.383-.786-1.593-1.59zm8.889-9.113L6.59 15.535l1.874 1.872 7.932-7.949-1.84-1.836zm3.108-3.095l-.087.096-1.956 1.942 1.833 1.831L19.41 6.44l.088-.082-.06-.055-1.772-1.775z" />
  </svg>
);
