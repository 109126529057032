import { combineReducers } from 'redux';

import teachers from './teacher/reducers';
import reports from './teacher/slices/emailReports/emailReportsSlice';
import modalViews from './teacher/slices/modalView/modalViewSlice';

const rootReducer = combineReducers({
  reports: reports,
  teachers: teachers,
  modalViews: modalViews,
});

export type ApplicationState = ReturnType<typeof rootReducer>;

export default rootReducer;
