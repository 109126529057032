import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconEnvelopeSvg } from './IconEnvelopeSvg';

export type IconEnvelopeProps = UseIconProps;

export function IconEnvelope(props: IconEnvelopeProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconEnvelopeSvg} {...iconProps} />;
}
