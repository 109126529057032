import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconDownloadSvg } from './IconDownloadSvg';

export type IconDownloadProps = UseIconProps;

export function IconDownload(props: IconDownloadProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconDownloadSvg} {...iconProps} />;
}
