import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconImageSvg } from './IconImageSvg';

export type IconImageProps = UseIconProps;

export function IconImage(props: IconImageProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconImageSvg} {...iconProps} />;
}
