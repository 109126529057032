import * as Constants from '../Constants';

function isPresent(s: string) {
  return !!s && s.trim().length > 0;
}

function belowMaxLength(maxLength: number, s: string) {
  return s.length <= maxLength;
}

function containsValidNameCharacters(s: string) {
  // names can contain ' and - so we need to make sure we allow them
  const invalidCharactersRegex = /[`!#$%^&*()_+=[\]{};:"\\|,<>/?~]/;
  const urlPatternsRegex =
    /^(http[s]?:\/\/|www\.|ftp:\/\/)?[a-zA-Z0-9]+(\.[a-zA-Z]{1,}|(\.[a-zA-Z]{2,}){1,2})(\/\S*)?$/;
  return !invalidCharactersRegex.test(s) && !urlPatternsRegex.test(s);
}

export function isValidName(name: string) {
  return (
    isPresent(name) &&
    belowMaxLength(Constants.MAX_STRING_LENGTH_255, name) &&
    containsValidNameCharacters(name)
  );
}
