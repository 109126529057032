import * as React from 'react';

import { SVGProps } from '../../types';

export const IconFolderSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.987 7.271l.018.074c.088.45.394.713.864.713h5.75c.763 0 1.381.62 1.381 1.383v9.177C21 19.38 20.382 20 19.619 20H4.381C3.618 20 3 19.38 3 18.618V6.765C3 5.79 3.788 5 4.762 5h6.286c.939 0 1.567.67 1.725 1.498l.214.773z" />
  </svg>
);
