import * as React from 'react';

import { SVGProps } from '../../types';

export const IconVisibilitySvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 5c6.52 0 11 5.529 11 7.028 0 .316-.199.808-.573 1.387l-.202.297-.111.153-.245.314C20.146 16.305 16.575 19 12 19s-8.146-2.695-9.87-4.82l-.244-.315a8.866 8.866 0 01-.111-.153l-.202-.297C1.199 12.835 1 12.344 1 12.028 1 10.528 5.48 5 12 5zm0 1.5c-2.56 0-4.987.984-7 2.617-.808.656-1.496 1.38-1.978 2.053a4.793 4.793 0 00-.453.751l-.048.106.046.103c.095.205.249.461.452.743.48.667 1.168 1.384 1.976 2.034C7.007 16.525 9.437 17.5 12 17.5c2.563 0 4.993-.975 7.005-2.593.808-.65 1.495-1.367 1.976-2.034.203-.282.357-.538.452-.743l.045-.103-.047-.106a4.793 4.793 0 00-.453-.75c-.482-.674-1.17-1.398-1.978-2.054C16.987 7.484 14.56 6.5 12 6.5zM12 8a4 4 0 110 8 4 4 0 010-8zm0 1.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" />
  </svg>
);
