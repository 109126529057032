import * as React from 'react';

import { SVGProps } from '../../types';

export const IconEllipsisSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M17 12a2 2 0 114 0 2 2 0 01-4 0zm-7 0a2 2 0 114 0 2 2 0 01-4 0zm-7 0a2 2 0 114 0 2 2 0 01-4 0z"
    />
  </svg>
);
