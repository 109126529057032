import * as React from 'react';

import { SVGProps } from '../../types';

export const IconVisibilityHiddenSvg = ({
  title,
  titleId,
  ...props
}: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      d="M3.016 1.956l4.262 4.037C8.67 5.393 10.26 5 12 5c2.607 0 4.874.883 6.651 2.018 1.249.798 2.303 1.735 3.053 2.591.375.428.685.848.905 1.232.205.356.391.78.391 1.187 0 .408-.186.83-.391 1.184-.221.382-.53.798-.905 1.222-.72.816-1.723 1.705-2.906 2.473l3.218 3.049a.75.75 0 01-1.032 1.088l-19-18a.75.75 0 011.032-1.088zm1.62 5.547l1.11 1.052a12.895 12.895 0 00-2.322 2.043 6.426 6.426 0 00-.733.99c-.17.295-.19.434-.191.44.002.01.025.148.19.434.158.272.402.607.73.978.654.741 1.599 1.576 2.725 2.29C7.738 16.74 9.733 17.5 12 17.5c.981 0 1.911-.142 2.78-.386l1.243 1.177A11.885 11.885 0 0112 19c-2.61 0-4.88-.876-6.658-2.003-1.245-.789-2.297-1.715-3.046-2.563a7.805 7.805 0 01-.905-1.222C1.186 12.858 1 12.436 1 12.028c0-.408.186-.83.39-1.187.221-.384.53-.804.906-1.232a14.251 14.251 0 012.34-2.106zm3.533 3.348l1.334 1.264A2.5 2.5 0 0012 14.5h.02l1.335 1.264a4 4 0 01-5.186-4.913zM12 6.5c-1.266 0-2.448.24-3.522.63l1.498 1.42a4 4 0 015.579 5.284l2.12 2.008.18-.112c1.126-.714 2.07-1.549 2.725-2.29a6.33 6.33 0 00.73-.978c.165-.286.188-.422.19-.433-.001-.007-.022-.146-.191-.441a6.432 6.432 0 00-.733-.99c-.656-.75-1.603-1.594-2.732-2.316C16.252 7.265 14.26 6.5 12 6.5zm0 3a2.5 2.5 0 00-.86.152l3.25 3.08a2.497 2.497 0 00-.622-2.5A2.5 2.5 0 0012 9.5z"
    />
  </svg>
);
