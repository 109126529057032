import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconCopyToSVG } from './IconCopyToSVG';

export function IconCopyTo({ ...props }: UseIconProps) {
  const iconProps = useIcon(props);
  return <Box tagName={IconCopyToSVG} {...iconProps} />;
}
