import * as React from 'react';

import { SVGProps } from '../../types';

export const IconDocumentSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M13.027 2c.409 0 .8.167 1.082.461l5.806 6.048a1.5 1.5 0 01.418 1.039V19a3 3 0 01-3 3H6.667a3 3 0 01-3-3V5a3 3 0 013-3h6.36zm-.565 1.5H6.667a1.5 1.5 0 00-1.493 1.356L5.167 5v14a1.5 1.5 0 001.355 1.493l.145.007h10.666a1.5 1.5 0 001.493-1.356l.007-.144v-8.776h-5.62a.75.75 0 01-.744-.647l-.007-.102V3.5zm5.58 5.224l-4.08-4.25v4.25h4.08z" />
  </svg>
);
