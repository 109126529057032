import { Discounts } from './types';

export function calculateFinalPrice(
  basePriceCents: number,
  discounts: Discounts,
  numSeats: number,
  numYears: number,
  couponDiscountCents = 0
) {
  if (
    basePriceCents <= 0 ||
    numSeats <= 0 ||
    numYears <= 0 ||
    couponDiscountCents < 0
  )
    throw new Error('Invalid inputs');

  const couponPricePerSeat = basePriceCents - couponDiscountCents;

  const percentageDiscount = 0;
  // NOTE: Discounts are now disabled
  // if (discounts?.seats) {
  //   for (const tier of discounts.seats) {
  //     if (numSeats >= tier.min && numSeats <= tier.max) {
  //       percentageDiscount = tier.percentage;
  //       break;
  //     }
  //   }
  // }

  const bulkDiscountPricePerSeat = basePriceCents * (1 - percentageDiscount);
  // Use the lowest discount between coupon and bulk purchase (not both)
  const isBulkDiscountLowest = bulkDiscountPricePerSeat < couponPricePerSeat;
  let effectivePricePerSeat = isBulkDiscountLowest
    ? bulkDiscountPricePerSeat
    : couponPricePerSeat;

  // Don't allow less than 1$ per seat
  if (effectivePricePerSeat <= 100) {
    throw new Error('Price too low');
  }

  effectivePricePerSeat = Math.round(effectivePricePerSeat);
  let finalPrice = effectivePricePerSeat * numSeats * numYears;
  finalPrice = Math.round(finalPrice);
  return {
    finalPriceCents: finalPrice,
    priceCentsPerSeat: effectivePricePerSeat,
    percentageDiscount: isBulkDiscountLowest ? percentageDiscount * 100 : 0,
    couponDiscountCents: isBulkDiscountLowest ? 0 : couponDiscountCents,
  };
}
