import Cookies from 'js-cookie';
const RANDOM_SEED_KEY = 'random_seed';
const RANDOM_MULTIPLICATION_FACTOR = 100000000;
export function getRandomSeedFromCookie() {
  try {
    let randomSeed = +Cookies.get(RANDOM_SEED_KEY);
    if (!randomSeed) {
      randomSeed = Math.random() * RANDOM_MULTIPLICATION_FACTOR;
      Cookies.set(RANDOM_SEED_KEY, `${randomSeed}`, { expires: 1 });
    }
    return randomSeed;
  } catch (error) {
    console.error(`Error accessing/generating random seed: ${error}`);
    return null;
  }
}
