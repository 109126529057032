import * as React from 'react';

import { SVGProps } from '../../types';

export const IconCopySvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M6.25 8a.75.75 0 01.102 1.493L6.25 9.5H4a.496.496 0 00-.494.42L3.5 10v10a.496.496 0 00.42.494L4 20.5h10a.496.496 0 00.494-.42L14.5 20v-2.25a.75.75 0 011.493-.102l.007.102V20a1.996 1.996 0 01-1.822 1.992L14 22H4a1.996 1.996 0 01-1.992-1.822L2 20V10a1.996 1.996 0 011.822-1.992L4 8h2.25zM20 2a2 2 0 012 2v10a2 2 0 01-2 2H10a2 2 0 01-2-2V4a2 2 0 012-2h10zm0 1.5H10a.5.5 0 00-.492.41L9.5 4v10a.5.5 0 00.41.492l.09.008h10a.5.5 0 00.492-.41L20.5 14V4a.5.5 0 00-.41-.492L20 3.5zm-5 2.25a.75.75 0 01.743.648l.007.102v1.75h1.75a.75.75 0 01.102 1.493l-.102.007h-1.75v1.75a.75.75 0 01-1.493.102l-.007-.102V9.75H12.5a.75.75 0 01-.102-1.493l.102-.007h1.75V6.5a.75.75 0 01.75-.75z" />
  </svg>
);
