import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconShareSvg } from './IconShareSvg';

export type IconShareProps = UseIconProps;

export function IconShare(props: IconShareProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconShareSvg} {...iconProps} />;
}
