import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconDocumentSvg } from './IconDocumentSvg';

export type IconDocumentProps = UseIconProps;

export function IconDocument(props: IconDocumentProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconDocumentSvg} {...iconProps} />;
}
