import * as React from 'react';

import { SVGProps } from '../../types';

export const IconPlusSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 4a1 1 0 01.993.883L13 5v6h6a1 1 0 01.117 1.993L19 13h-6v6a1 1 0 01-1.993.117L11 19v-6H5a1 1 0 01-.117-1.993L5 11l6-.001V5a1 1 0 011-1z" />
  </svg>
);
