import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconStrokeSvg } from './IconStrokeSvg';

export type IconStrokeProps = UseIconProps;

export function IconStroke(props: IconStrokeProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconStrokeSvg} {...iconProps} />;
}
