import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconPlusSvg } from './IconPlusSvg';

export type IconPlusProps = UseIconProps;

export function IconPlus(props: IconPlusProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconPlusSvg} {...iconProps} />;
}
