import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconStudentsSvg } from './IconStudentsSvg';

export type IconStudentsProps = UseIconProps;

export function IconStudents(props: IconStudentsProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconStudentsSvg} {...iconProps} />;
}
