import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconTrashSvg } from './IconTrashSvg';

export type IconTrashProps = UseIconProps;

export function IconTrash(props: IconTrashProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconTrashSvg} {...iconProps} />;
}
