import * as React from 'react';

import { SVGProps } from '../../types';

export const IconMoveSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M11.048 3.25c1.207 0 2.137.803 2.412 1.884l.036.164.221.8.024.103c.017.087.022.104.084.107h5.794c1.126 0 2.048.875 2.126 1.98l.005.153v9.177a2.132 2.132 0 01-1.979 2.127l-.152.005H4.381a2.132 2.132 0 01-2.126-1.98l-.005-.152V16h1.5v1.618c0 .317.234.58.538.625l.093.007h15.238a.632.632 0 00.624-.539l.007-.093V8.44a.632.632 0 00-.538-.625l-.093-.007h-5.75c-.774 0-1.364-.453-1.56-1.149l-.045-.189-.227-.832c-.096-.5-.417-.83-.864-.882l-.125-.007H4.762c-.522 0-.951.395-1.006.904l-.006.11V10h-1.5V5.765a2.513 2.513 0 012.347-2.51l.165-.005h6.286zM9.446 9.147l.084.073 3 3a.75.75 0 01.073.976l-.073.084-3 3a.75.75 0 01-1.133-.976l.073-.084 1.719-1.72H3a.75.75 0 01-.102-1.493L3 12h7.189l-1.72-1.72a.75.75 0 01-.072-.976l.073-.084a.75.75 0 01.976-.073z" />
  </svg>
);
