import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import reducers, { ApplicationState } from './rootReducer';

const store = configureStore({
  reducer: reducers,
});
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<
  Promise<void>,
  ApplicationState,
  unknown,
  Action<string>
>;
export default store;
