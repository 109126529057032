import * as React from 'react';

import { SVGProps } from '../../types';

export const IconUserSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm.005 6.415c-2.187 0-3.378 1.656-3.45 4.078l-.004.236v.742l-.741.008.005.003v.008l-.03.201-.018.19c-.032.4.034.945.119 1.221.14.458.33.724.536.782l.07.012.612.038.085.608c.067.489.182.82.352 1.1l.094.141.378.482c.195.267.313.53.349.843l.001.026.025.091a2.13 2.13 0 01-.061 1.22l-.073.185-.092.185c-.243.447-.625.793-1.192 1.133l-.314.176-.43.213-1.533.725A10.451 10.451 0 0012 22.5c1.936 0 3.75-.524 5.306-1.437l-1.553-.735-.434-.216-.291-.164c-.567-.34-.95-.686-1.193-1.133a2.2 2.2 0 01-.26-1.427l.034-.163.024-.091.003-.026c.023-.209.084-.396.18-.576l.078-.134.09-.133.296-.374.1-.132c.187-.266.316-.57.398-1.017l.031-.2.085-.608.613-.038c.231-.014.45-.286.606-.795.084-.275.15-.82.118-1.22l-.013-.135-.036-.256.001-.008.005-.003-.741-.008v-.742c0-2.548-1.194-4.314-3.442-4.314zM12 1.5C6.201 1.5 1.5 6.201 1.5 12c0 3.245 1.472 6.147 3.785 8.073l.1-.053 1.517-.713.913-.438.209-.108c.464-.255.712-.464.82-.663a.745.745 0 00.102-.426l-.014-.088-.056-.133-.003-.148a.167.167 0 00-.035-.1l-.036-.052-.201-.253-.214-.281a3.651 3.651 0 01-.454-.886l-.081-.257-.055-.213-.054-.02c-.537-.234-.91-.712-1.161-1.33l-.071-.189-.06-.18a5.402 5.402 0 01-.18-1.78c.05-.612.235-1.127.587-1.444l.124-.098.087-.054.01-.144c.219-2.77 1.743-4.866 4.443-5.088l.249-.015.234-.004c2.91 0 4.588 2.057 4.892 4.865l.022.242.009.144.088.054c.352.246.566.664.662 1.177l.03.197.018.167c.047.595-.04 1.33-.18 1.78-.224.73-.586 1.317-1.136 1.623l-.155.077-.055.02-.054.214c-.084.295-.19.557-.321.797l-.103.176-.111.17-.096.128-.306.387-.05.07a.197.197 0 00-.032.069l-.003.031-.003.148-.054.125-.016.096a.744.744 0 00.101.425c.095.175.298.356.658.57l.164.094.21.108.503.244 2.03.959A10.478 10.478 0 0022.5 12c0-5.799-4.701-10.5-10.5-10.5z" />
  </svg>
);
