import * as React from 'react';

import { SVGProps } from '../../types';

export const IconRestoreSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M3.12 2.568a.75.75 0 0 1 .75.75V8.81l5.493.001a.75.75 0 1 1 0 1.5L2.37 10.31V3.318a.75.75 0 0 1 .75-.75Zm8.11.734A8.75 8.75 0 1 1 9.734 20.4a.75.75 0 0 1 .42-1.44A7.25 7.25 0 1 0 7.522 6.446a.75.75 0 0 1-.964-1.149 8.75 8.75 0 0 1 4.672-1.995Z" />
  </svg>
);
