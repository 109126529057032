import { ClientApps } from './types';
const platform = require('Platform');

export const getClientApp = (): ClientApps => {
  if (platform.isAndroidApp) {
    return ClientApps.AndroidApp;
  } else if (platform.isIosApp) {
    return ClientApps.iOSApp;
  } else if (platform.isIosView) {
    return ClientApps.ShowbieIOSApp;
  } else if (window['post_message_origin']) {
    return ClientApps.ShowbieWebApp;
  } else {
    return ClientApps.WebApp;
  }
};
