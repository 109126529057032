import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconSearchSvg } from './IconSearchSvg';

export type IconSearchProps = UseIconProps;

export function IconSearch(props: IconSearchProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconSearchSvg} {...iconProps} />;
}
