import * as React from 'react';

import { SVGProps } from '../../types';

export const IconAISvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13.7152 8.85934C13.8061 8.58517 14.1939 8.58516 14.2848 8.85934L15.0195 11.0765C15.3177 11.9763 16.0237 12.6823 16.9235 12.9805L19.1407 13.7152C19.4148 13.8061 19.4148 14.1939 19.1407 14.2848L16.9235 15.0195C16.0237 15.3177 15.3177 16.0237 15.0195 16.9235L14.2848 19.1407C14.1939 19.4148 13.8061 19.4148 13.7152 19.1407L12.9805 16.9235C12.6823 16.0237 11.9763 15.3177 11.0765 15.0195L8.85934 14.2848C8.58517 14.1939 8.58516 13.8061 8.85934 13.7152L11.0765 12.9805C11.9763 12.6823 12.6823 11.9763 12.9805 11.0765L13.7152 8.85934Z"
      fill="currentColor"
    />
    <path
      d="M7.72118 4.70204C7.82141 4.44969 8.17859 4.44969 8.27882 4.70204L8.65924 5.65992C8.96418 6.42774 9.57226 7.03582 10.3401 7.34076L11.298 7.72118C11.5503 7.82141 11.5503 8.17859 11.298 8.27882L10.3401 8.65924C9.57226 8.96418 8.96418 9.57226 8.65924 10.3401L8.27882 11.298C8.17859 11.5503 7.82141 11.5503 7.72118 11.298L7.34076 10.3401C7.03582 9.57226 6.42774 8.96418 5.65992 8.65924L4.70204 8.27882C4.44969 8.17859 4.44969 7.82141 4.70204 7.72118L5.65992 7.34076C6.42774 7.03582 7.03582 6.42774 7.34076 5.65992L7.72118 4.70204Z"
      fill="currentColor"
    />
  </svg>
);
