import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// import {ReportsModalFeedbackProps } from '~teacher/components/ReportsModal/ReportsModalFeedback';}
import { ReportsModalScreen } from '~teacher/enums';

export interface ModalFeedbackProps {
  isError: boolean;
  title: string;
  message: string;
  buttonText: string;
  buttonAction?: () => void;
  response?: any;
  previousModalView: string;
}

export type modalViewSliceState = {
  modalView: string;
  previousModalView: string;
  feedbackProps: null | ModalFeedbackProps;
};

export const initialState = (): modalViewSliceState => ({
  modalView: ReportsModalScreen.OPTIONS_VIEW,
  previousModalView: null,
  feedbackProps: null,
});

const modalViewSlice = createSlice({
  name: 'modalViewData',
  initialState: initialState(),
  reducers: {
    navigateModal(state, action) {
      const modalView = action.payload;
      state.modalView = modalView;
      state.previousModalView = ReportsModalScreen.OPTIONS_VIEW;
      return state;
    },
    showFeedbackView(
      state,
      action: PayloadAction<{
        feedbackProps: ModalFeedbackProps;
      }>
    ) {
      const { feedbackProps } = action.payload;
      const { previousModalView } = feedbackProps;
      state.feedbackProps = feedbackProps;
      state.modalView = ReportsModalScreen.FEEDBACK_VIEW;
      state.previousModalView = previousModalView;
      return state;
    },
    showOptionsView(state) {
      state.modalView = ReportsModalScreen.OPTIONS_VIEW;
      state.previousModalView = null;
      return state;
    },
  },
});

const { navigateModal, showFeedbackView, showOptionsView } =
  modalViewSlice.actions;

export { navigateModal, showFeedbackView, showOptionsView };
export default modalViewSlice.reducer;
