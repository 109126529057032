import amplitude from 'amplitude-js';

import { getClientApp } from './getClientApp';
import { EventTypes } from './types';

const initializeAmplitude = (amplitudeUserData) => {
  try {
    const amplitudeClient = amplitude.getInstance();
    if (!amplitudeUserData.public_id) {
      console.error('No public id, skipping amplitude initialization');
      return;
    }
    amplitudeClient.init(window.amplitude_key, amplitudeUserData.public_id);

    amplitudeUserData['client_app'] = getClientApp();
    amplitudeUserData['Version'] = window.app_version;
    amplitudeClient.setUserProperties(amplitudeUserData);
    console.log('Amplitude initialized');
  } catch (err) {
    console.error('Failed to initialize amplitude', err);
  }
};

const sendTeacherAmplitudeEvent = (eventType: EventTypes, data = {}) => {
  try {
    const amplitudeClient = amplitude.getInstance();
    amplitudeClient.logEvent(eventType, data);
    __DEV__ && console.log(`Send Amplitude Event ${eventType}`, data);
  } catch (err) {
    console.error('Failed to send amplitude event', err);
  }
};

const clearAmplitudeUserSession = () => {
  try {
    amplitude.getInstance().setUserId(null);
  } catch (err) {
    console.error('Failed to clear amplitude session', err);
  }
};

export {
  initializeAmplitude,
  sendTeacherAmplitudeEvent,
  clearAmplitudeUserSession,
};
