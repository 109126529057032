import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconRestoreSvg } from './IconRestoreSvg';

export function IconRestore({ ...props }: UseIconProps) {
  const iconProps = useIcon(props);
  return <Box tagName={IconRestoreSvg} {...iconProps} />;
}
