import * as React from 'react';

import { SVGProps } from '../../types';

export const IconChevronSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.823 8.763a1 1 0 011.497 1.32l-.083.094-6.53 6.53a1 1 0 01-1.32.083l-.094-.083-6.5-6.5a1 1 0 011.32-1.497l.094.083L12 14.585l5.823-5.822z" />
  </svg>
);
