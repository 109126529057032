import * as React from 'react';

import { SVGProps } from '../../types';

export const IconImageSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <rect
      x="2.75"
      y="4.75"
      width="18.5"
      height="14.5"
      rx="1.25"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <circle cx="7" cy="9" r="1.25" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M5.5 16L8.5 13L10.5 15L16 9.5L18.5 12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
