import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconPauseSvg } from './IconPauseSvg';

export type IconPauseProps = UseIconProps;

export function IconPause(props: IconPauseProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconPauseSvg} {...iconProps} />;
}
