import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// import { StateProps } from '~teacher/components/ReportsModal/ReportsModalFeedback';
import { Student } from '~teacher/models/reports';

export type emailsReportsSliceState = {
  students: Array<Student>;
};

export const initialState = (): emailsReportsSliceState => ({
  students: [],
});

const emailReportsSlice = createSlice({
  name: 'emailReportsData',
  initialState: initialState(),
  reducers: {
    selectStudent(state, action: PayloadAction<{ studentId: number }>) {
      const { studentId } = action.payload;
      const updatedStudents = state.students.map((student: Student) => {
        const isSelected =
          student.id === studentId ? !student.isSelected : student.isSelected;
        return { ...student, isSelected };
      });

      state.students = updatedStudents;
      return state;
    },

    toggleAllStudents(state) {
      const updatedStudents = state.students.map((student: Student) => {
        const isSelected = !!student.email && student.hasParticipated;
        return { ...student, isSelected };
      });

      state.students = updatedStudents;
      return state;
    },

    untoggleAllStudents(state) {
      const updatedStudents = state.students.map((student: Student) => {
        return { ...student, isSelected: false };
      });

      state.students = updatedStudents;
      return state;
    },

    populateStudentRoster(
      state,
      action: PayloadAction<{ studentRoster: Array<Student> }>
    ) {
      const { studentRoster } = action.payload;
      state.students = studentRoster;
      return state;
    },
  },
});

const {
  toggleAllStudents,
  selectStudent,
  untoggleAllStudents,
  populateStudentRoster,
} = emailReportsSlice.actions;

export {
  toggleAllStudents,
  selectStudent,
  untoggleAllStudents,
  populateStudentRoster,
};

export default emailReportsSlice.reducer;
