import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconVisibilityHiddenSvg } from './IconVisibilityHiddenSvg';
import { IconVisibilitySvg } from './IconVisibilitySvg';

export type IconVisibilityProps = UseIconProps & { hidden?: boolean };

export function IconVisibility({ hidden, ...props }: IconVisibilityProps) {
  const iconProps = useIcon(props);

  return (
    <Box
      tagName={hidden ? IconVisibilityHiddenSvg : IconVisibilitySvg}
      {...iconProps}
    />
  );
}
