import * as React from 'react';

import { SVGProps } from '../../types';

export const IconGoogleDriveSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14.32 3a2 2 0 011.75 1.032l5.391 9.744a2 2 0 01-.005 1.945l-2.383 4.256A2 2 0 0117.328 21H6.672a2 2 0 01-1.745-1.023l-2.383-4.256a2 2 0 01-.005-1.945l5.39-9.744A2 2 0 019.68 3h4.642zm5.26 13h-4.67l-.015.01-.005-.01H9.11l-1.97 3.5h10.188a.5.5 0 00.386-.182l.05-.074L19.58 16zM8.906 5.363L3.85 14.502a.5.5 0 00-.039.392l.04.094L5.9 18.646l5.238-9.314-2.233-3.969zm3.094 5.5L9.954 14.5h4.092L12 10.863zM14.32 4.5h-4.18l5.627 10h4.38l-5.389-9.742a.5.5 0 00-.348-.25l-.09-.008z" />
  </svg>
);
