import * as React from 'react';

import { SVGProps } from '../../types';

export const IconArrowSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12 3h.018l.041.002.035.002.023.003.026.003a1.003 1.003 0 01.086.016l.032.008.03.01a.997.997 0 01.23.102l.015.01.052.035a1 1 0 01.037.029l.082.073 6.53 6.53a1 1 0 01-1.32 1.497l-.094-.083-4.824-4.823L13 20a1 1 0 01-1.993.117L11 20l-.001-13.585-4.822 4.822a1 1 0 01-1.497-1.32l.083-.094 6.53-6.53.082-.073.006-.006-.088.079a1.008 1.008 0 01.415-.25l.031-.009a.993.993 0 01.032-.008l.026-.005a1.003 1.003 0 01.086-.014l.023-.003.035-.002.04-.002H12z" />
  </svg>
);
