import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconChevronSvg } from './IconChevronSvg';

export const directionClassMap = {
  up: 'rotate-180',
  right: '-rotate-90',
  down: '',
  left: 'rotate-90',
};

export type IconChevronProps = UseIconProps & {
  direction?: 'up' | 'right' | 'down' | 'left';
};

export function IconChevron({
  direction = 'down',
  ...props
}: IconChevronProps) {
  const iconProps = useIcon(props);
  const iconClass = [
    'transform origin-center transition-transform duration-300 ease-in-out',
    directionClassMap[direction],
  ].join(' ');

  return <Box tagName={IconChevronSvg} className={iconClass} {...iconProps} />;
}
