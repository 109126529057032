import * as React from 'react';

import { SVGProps } from '../../types';

export const IconSharedSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M4.56 14.126a3.429 3.429 0 012.748 5.48A7.857 7.857 0 0012 21.15a7.88 7.88 0 002.9-.549c.295.441.654.837 1.063 1.174A9.35 9.35 0 0112 22.65a9.36 9.36 0 01-5.861-2.05 3.429 3.429 0 11-1.579-6.473zm12.663-8.693a9.391 9.391 0 014.07 9.237 3.429 3.429 0 11-1.446-.52 7.888 7.888 0 00-3.094-7.21 5.37 5.37 0 00.41-1.182l.06-.325zM4.56 15.626a1.929 1.929 0 100 3.857 1.929 1.929 0 000-3.857zm14.88 0a1.929 1.929 0 100 3.857 1.929 1.929 0 000-3.857zM11.821 1.15a3.429 3.429 0 11-3.093 4.908A7.907 7.907 0 004.183 12.1a5.396 5.396 0 00-1.549.335 9.408 9.408 0 015.758-7.868 3.43 3.43 0 013.43-3.417zm0 1.5a1.929 1.929 0 100 3.857 1.929 1.929 0 000-3.857z" />
  </svg>
);
