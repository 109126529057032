import * as React from 'react';

import { SVGProps } from '../../types';

export const IconExpandSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M9.53 14.72a.75.75 0 01.073.976l-.073.084-3.97 3.97h2.69l.102.007a.75.75 0 010 1.486l-.102.007h-4.5l-.102-.007a.75.75 0 01-.641-.641L3 20.5V16l.007-.102a.75.75 0 01.641-.641l.102-.007.102.007a.75.75 0 01.641.641L4.5 16v2.688l3.97-3.968a.75.75 0 011.06 0zm4.94 0a.75.75 0 01.976-.073l.084.073 3.97 3.968V16l.007-.102a.75.75 0 01.533-.618l.108-.023.102-.007.102.007a.75.75 0 01.618.533l.023.108L21 16v4.5l-.007.102a.75.75 0 01-.533.618l-.108.023-.102.007h-4.5l-.102-.007a.75.75 0 01-.097-1.466l.097-.02.102-.007h2.69l-3.97-3.97-.073-.084a.75.75 0 01.073-.976zM8.25 3.25l.102.007a.75.75 0 01.641.641L9 4l-.007.102a.75.75 0 01-.641.641l-.102.007H5.562L9.53 8.72a.75.75 0 01-.976 1.133L8.47 9.78 4.5 5.81V8.5l-.007.102a.75.75 0 01-1.486 0L3 8.5V4l.007-.102a.75.75 0 01.641-.641l.102-.007h4.5zm12 0l.102.007a.75.75 0 01.618.533l.023.108L21 4v4.5l-.007.102a.75.75 0 01-1.466.097l-.02-.097L19.5 8.5V5.81l-3.97 3.97-.084.073a.75.75 0 01-1.049-1.05l.073-.083 3.968-3.97H15.75l-.102-.007a.75.75 0 01-.618-.533l-.023-.108L15 4l.007-.102a.75.75 0 01.533-.618l.108-.023.102-.007h4.5z" />
  </svg>
);
