import * as React from 'react';

import { SVGProps } from '../../types';

export const IconSearchSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M9.88 2.25a7.631 7.631 0 015.801 12.589l5.85 5.85a.75.75 0 01-.977 1.133l-.084-.072-5.87-5.872A7.631 7.631 0 119.88 2.25zm0 1.5a6.131 6.131 0 10.002 12.263A6.131 6.131 0 009.88 3.75z" />
  </svg>
);
