import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconExternalSvg } from './IconExternalSvg';

export type IconExternalProps = UseIconProps;

export function IconExternal(props: IconExternalProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconExternalSvg} {...iconProps} />;
}
