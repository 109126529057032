import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconCopySvg } from './IconCopySvg';

export type IconCopyProps = UseIconProps;

export function IconCopy(props: IconCopyProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconCopySvg} {...iconProps} />;
}
