import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconResumeSvg } from './IconResumeSvg';

export type IconResumeProps = UseIconProps;

export function IconResume(props: IconResumeProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconResumeSvg} {...iconProps} />;
}
