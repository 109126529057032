import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconFinishSvg } from './IconFinishSvg';

export type IconFinishProps = UseIconProps;

export function IconFinish(props: IconFinishProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconFinishSvg} {...iconProps} />;
}
