import * as React from 'react';

import { SVGProps } from '../../types';

export const IconStrokeSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17.293 5.293a1 1 0 011.497 1.32l-.083.094-12 12a1 1 0 01-1.497-1.32l.083-.094 12-12z" />
  </svg>
);
