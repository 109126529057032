import * as React from 'react';

import { Box } from '~shared/components/Box';
import { IconAISvg } from '~shared/components/icons/standard/IconAi/IconAISvg';

import { UseIconProps, useIcon } from '../../useIcon';

export function IconAI({ ...props }: UseIconProps) {
  const iconProps = useIcon(props);
  return <Box tagName={IconAISvg} {...iconProps} />;
}
