export function getRandomSeedFromStudentId(studentId: string) {
  if (!studentId) {
    return null;
  }

  return studentId
    .split('')
    .reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.codePointAt(0),
      0
    );
}
