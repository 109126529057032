import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconExpandSvg } from './IconExpandSvg';

export type IconExpandProps = UseIconProps;

export function IconExpand(props: IconExpandProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconExpandSvg} {...iconProps} />;
}
