import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconSidebarSvg } from './IconSidebarSvg';

export type IconSidebarProps = UseIconProps;

export function IconSidebar(props: IconSidebarProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconSidebarSvg} {...iconProps} />;
}
