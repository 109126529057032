import * as React from 'react';

import { SVGProps } from '../../types';

export const IconStudentsSvg = ({
  title,
  titleId,
  className,
  ...props
}: SVGProps) => (
  <div className={className + ' leading-none'}>
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      focusable="false"
      fill="currentColor"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM8 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm9.5-.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 1.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
        fill="currentColor"
      />
      <path
        d="M6.75 11.25A5.75 5.75 0 0 0 1 17v4h1.5v-4a4.25 4.25 0 0 1 4.25-4.25h2.5A4.25 4.25 0 0 1 13.5 17v4H15v-4a5.725 5.725 0 0 0-.708-2.766A3.234 3.234 0 0 1 16 13.75h2A3.25 3.25 0 0 1 21.25 17v2h1.5v-2A4.75 4.75 0 0 0 18 12.25h-2c-.959 0-1.851.284-2.598.773A5.733 5.733 0 0 0 9.25 11.25h-2.5Z"
        fill="currentColor"
      />
    </svg>
  </div>
);
