import * as React from 'react';

import { SVGProps } from '../../types';

export const IconResumeSvg = ({
  title,
  titleId,
  className,
  ...props
}: SVGProps) => (
  <div className={className + ' leading-none'}>
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      focusable="false"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M9.58824 17.7696C8.7556 18.2137 7.75 17.6103 7.75 16.6667V7.33333C7.75 6.38968 8.7556 5.78632 9.58824 6.23039L18.3382 10.8971C19.2206 11.3676 19.2206 12.6324 18.3382 13.1029L9.58824 17.7696Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  </div>
);
