import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconFolderSvg } from './IconFolderSvg';

export type IconFolderProps = UseIconProps;

export function IconFolder(props: IconFolderProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconFolderSvg} {...iconProps} />;
}
