import * as React from 'react';

import { SVGProps } from '../../types';

export const IconSidebarSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M18.25 3a3.75 3.75 0 013.745 3.55l.005.2v10.5a3.75 3.75 0 01-3.55 3.745l-.2.005H5.75a3.75 3.75 0 01-3.745-3.55L2 17.25V6.75a3.75 3.75 0 013.55-3.745L5.75 3h12.5zM7 4.5H5.75a2.25 2.25 0 00-2.245 2.096L3.5 6.75v10.5a2.25 2.25 0 002.096 2.245l.154.005H7v-15zm11.25 0H8.5v15h9.75a2.25 2.25 0 002.245-2.096l.005-.154V6.75a2.25 2.25 0 00-2.096-2.245L18.25 4.5zm-4.804 3.897l.084.073 3 3a.75.75 0 01.073.976l-.073.084-3 3a.75.75 0 01-1.133-.976l.073-.084L14.939 12l-2.47-2.47a.75.75 0 01-.072-.976l.073-.084a.75.75 0 01.976-.073z" />
  </svg>
);
