import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconFolderAddSvg } from './IconFolderAddSvg';

export type IconFolderAddProps = UseIconProps;

export function IconFolderAdd(props: IconFolderAddProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconFolderAddSvg} {...iconProps} />;
}
