import * as React from 'react';

import { SVGProps } from '../../types';

export const IconMergeSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M12.312 13.804l-.083.143-2.015 3.808a3.75 3.75 0 01-3.096 1.989l-.218.006H4a.75.75 0 01-.102-1.493L4 18.25h2.9a2.25 2.25 0 001.905-1.054l.083-.143 2.016-3.808c.263-.498.63-.92 1.065-1.246a3.707 3.707 0 01-.957-1.054l-.108-.19-2.016-3.808a2.25 2.25 0 00-1.823-1.19L6.9 5.75H4a.75.75 0 01-.102-1.493L4 4.25h2.9c1.316 0 2.53.69 3.206 1.805l.108.19 2.015 3.808a2.25 2.25 0 001.823 1.19l.166.007h5.971l-2.72-2.72a.75.75 0 01-.072-.976l.073-.084a.75.75 0 01.976-.073l.084.073 4 4a.75.75 0 01.073.976l-.073.084-4 4a.75.75 0 01-1.133-.976l.073-.084 2.719-2.72h-5.971a2.25 2.25 0 00-1.906 1.054z" />
  </svg>
);
