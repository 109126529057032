import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  MediaResourceTypes,
  QuestionTypes,
} from '@showbie-socrative/socrative-utils';
import { omit } from 'underscore';

import unescapeLegacyHTML from '~shared/utils/unescapeLegacyHTML';
import { createQuestion } from '~teacher/models/createQuestionByType';
import {
  Quiz,
  QuizAnswer,
  QuizQuestion,
  QuizStandard,
} from '~teacher/models/quizzes';

import { EditQuizStatus } from './EditQuizStatus';

export interface LegacyStandard {
  dirty: boolean;
  complete: boolean;
  standardsObject: {
    subject: {
      id: number;
      name: string;
    } | null;
    core: number | null;
    class: number | null;
    standard: {
      id: number;
      short_description: string;
      name: string;
    } | null;
  };
}

export type quizSliceState = {
  status: EditQuizStatus;
  quizzes: { [key: string]: Quiz };
  questions: { [key: string]: QuizQuestion };
  answers: { [key: string]: QuizAnswer };
  editQuiz: null | (Quiz & { isDirty: boolean });
  editQuestion: null | (QuizQuestion & { isDirty: boolean });
  editAnswers: { [key: string]: Exclude<QuizAnswer, 'order'> };
  standardModel: LegacyStandard;
  error: unknown;
};

export const initialState = (): quizSliceState => ({
  quizzes: {},
  questions: {},
  answers: {},
  status: EditQuizStatus.Loading,
  editAnswers: {},
  editQuiz: null,
  editQuestion: null,
  standardModel: null,
  error: null,
});

const setTemporaryOrders = (state: quizSliceState, order: number) => {
  for (const key in state.questions) {
    const question = state.questions[key];
    if (order >= 0 && question.order >= order) {
      question.temporaryOrder = question.order + 1;
    } else {
      question.temporaryOrder = null;
    }
  }
};
const resetTemporaryOrders = (state: quizSliceState) =>
  setTemporaryOrders(state, -1);

const quizSlice = createSlice({
  name: 'quizData',
  initialState: initialState(),
  reducers: {
    setQuizzes(
      state,
      action: PayloadAction<{ quiz: { [key: string]: Quiz }; id: number }>
    ) {
      const { quiz, id } = action.payload;
      if (!quiz) throw new Error('No valid quizzes were passed');

      for (const stateQuiz in state.quizzes) {
        if (
          quiz[id] &&
          state.quizzes[stateQuiz].socNumber === quiz[id].socNumber
        ) {
          state.quizzes = omit(state.quizzes, stateQuiz);
          break;
        }
      }

      // check if the quiz exists, if it does
      state.quizzes = { ...state.quizzes, ...quiz };
      return state;
    },
    setQuizQuestions(
      state,
      action: PayloadAction<{ questions: { [key: string]: QuizQuestion } }>
    ) {
      const { questions } = action.payload;
      state.questions = { ...state.questions, ...questions };
      return state;
    },
    setQuizAnswers(
      state,
      action: PayloadAction<{
        answers: { [key: string]: QuizAnswer };
        questionType?: QuestionTypes;
      }>
    ) {
      const { answers, questionType } = action.payload;

      if (!answers) return state;

      const newAnswerState = Object.entries(answers).reduce(
        (aggregator, [key, value]) => {
          const { text } = value;
          const answer: QuizAnswer = {
            ...value,
            text:
              questionType === QuestionTypes.FreeResponse
                ? unescapeLegacyHTML(text)
                : text,
          };
          aggregator[key] = answer;
          return aggregator;
        },
        {
          ...state.answers,
        }
      );
      state.answers = newAnswerState;
      return state;
    },
    beginEditingQuestion(state, action: PayloadAction<{ questionId: string }>) {
      const { questionId } = action.payload;
      const question = state.questions[questionId];
      const answers = question.answers.reduce((aggregator, answerId) => {
        const answer = { ...state.answers[answerId] };
        aggregator[answerId] = answer;
        return aggregator;
      }, {});

      state.editQuestion = { ...question, isDirty: false };
      state.editAnswers = answers;
      return state;
    },
    finishEditingQuestion(state) {
      const {
        editQuestion: { questionId },
      } = state;
      // If we finish editing a question with a string for an id, that means it hasn't been persisted
      // Therefore when we're done with it we can get rid of it :)
      if (typeof questionId === 'string')
        state.editQuiz.questions = state.editQuiz.questions.filter(
          (id) => id !== questionId
        );
      state.editQuestion = null;
      state.editAnswers = {};
      resetTemporaryOrders(state);
      return state;
    },
    refreshEditingQuiz(
      state,
      action: PayloadAction<{ quizId: string | number }>
    ) {
      const { quizId } = action.payload;

      const quiz = state.quizzes[quizId];

      state.editQuiz = {
        ...state.editQuiz,
        ...quiz,
        questions: [
          ...new Set([...quiz.questions, ...state.editQuiz.questions]),
        ],
      };
      return state;
    },
    beginEditingQuiz(
      state,
      action: PayloadAction<{ quizId: string | number }>
    ) {
      const { quizId } = action.payload;
      const quiz = state.quizzes[quizId];

      state.editQuiz = { ...quiz, isDirty: false };
      state.editQuestion = null;
      state.editAnswers = null;

      return state;
    },
    removeQuiz(state, action: PayloadAction<{ quizId: number }>) {
      const { quizId } = action.payload;
      const { quizzes, answers, questions } = state;
      const quizToRemove = quizzes[quizId];
      if (!quizToRemove) return state;
      const { questionsToRemove, answersToRemove } = quizToRemove.questions
        .map((questionId) => state.questions[questionId])
        .filter(Boolean)
        .reduce(
          (aggregator, question) => {
            const { questionId, answers } = question;
            aggregator.questionsToRemove.push(`${questionId}`);
            aggregator.answersToRemove = aggregator.answersToRemove.concat(
              answers.map((a) => `${a}`)
            );
            return aggregator;
          },
          {
            questionsToRemove: [],
            answersToRemove: [],
          }
        );

      const newAnswers = Object.entries(answers).reduce(
        (aggregator, [key, value]) => {
          if (!answersToRemove.includes(key)) {
            aggregator[key] = value;
          }
          return aggregator;
        },
        {}
      );

      const newQuestions = Object.entries(questions).reduce(
        (aggregator, [key, value]) => {
          if (!questionsToRemove.includes(key)) {
            aggregator[key] = value;
          }
          return aggregator;
        },
        {}
      );

      const newQuizzes = Object.entries(quizzes).reduce(
        (aggregator, [key, value]) => {
          if (key !== `${quizId}`) {
            aggregator[key] = value;
          }
          return aggregator;
        },
        {}
      );
      state.quizzes = newQuizzes;
      state.answers = newAnswers;
      state.questions = newQuestions;
    },
    finishEditingQuiz(state) {
      state.editQuiz = null;
      state.editQuestion = null;
      state.editAnswers = null;
      return state;
    },
    removeQuestion(
      state,
      action: PayloadAction<{ questionIds: (string | number)[] }>
    ) {
      // Remove from existing questions...
      const { questionIds } = action.payload;

      questionIds.forEach((questionId) => {
        const question = state.questions[questionId];
        if (question) {
          const newAnswers = Object.entries(state.answers).reduce(
            (aggregator, [key, value]) => {
              if (!question.answers.includes(key)) {
                aggregator[key] = value;
              }
              return aggregator;
            },
            {}
          );
          state.answers = newAnswers;
          const newQuestions = Object.entries(state.questions).reduce(
            (aggregator, [key, value]) => {
              if (key !== questionId) {
                aggregator[key] = value;
              }
              return aggregator;
            },
            {}
          );
          state.questions = newQuestions;
          resetTemporaryOrders(state);
        }
        // remove from edit question
        const editQuiz = state.editQuiz;
        editQuiz.questions = editQuiz.questions.filter(
          (id) => `${questionId}` !== `${id}`
        );
        editQuiz.isDirty = true;

        Object.values(state.quizzes).forEach((quiz) => {
          if (quiz?.questions) {
            quiz.questions = quiz.questions.filter((id) => id !== questionId);
          }
        });
      });

      // Check if the edit question still exists, if it doesn't we are no longer editing
      if (questionIds.includes(`${state.editQuestion?.questionId}`)) {
        state.editQuestion = null;
        state.editAnswers = {};
        resetTemporaryOrders(state);
      }

      return state;
    },
    updateQuizQuestionOrder(
      state,
      action: PayloadAction<{
        questionOrderMap: Array<{ order: number; questionId: number }>;
      }>
    ) {
      const { questions } = state;
      const {
        payload: { questionOrderMap },
      } = action;
      questionOrderMap.forEach(({ order, questionId }) => {
        const question = questions[questionId];
        if (question) {
          question.order = order;
        }
      });
      return state;
    },
    copyQuestion(
      state,
      action: PayloadAction<{
        questionId: string;
        showAdvancedQuizEditor: boolean;
      }>
    ) {
      const { questionId, showAdvancedQuizEditor } = action.payload;
      const oldQuestion = state.questions[questionId];
      const newAnswers = oldQuestion.answers.map((answerId, index) => ({
        ...state.answers[answerId],
        id: `editing-${index}`,
      }));
      const editQuiz = state.editQuiz;
      const position = oldQuestion.order - 1;
      const newQuestionId = 'editing';
      editQuiz.questions.splice(position, 0, newQuestionId);

      setTemporaryOrders(state, oldQuestion.order + 1);

      const newQuestion: QuizQuestion = {
        ...oldQuestion,
        order: oldQuestion.order + 1,
        questionId: newQuestionId,
        createdById: null,
        createdDate: null,
        answers: newAnswers.map((answer) => answer.id),
      };

      if (!showAdvancedQuizEditor) {
        newQuestion.gradingWeight = 1;
      }
      state.editQuestion = { ...newQuestion, isDirty: true };
      state.editAnswers = newAnswers.reduce((aggregator, value) => {
        aggregator[value.id] = value;
        return aggregator;
      }, {} as { [key: string]: QuizAnswer });

      return state;
    },
    changeStatusToLoading(state) {
      state.status = EditQuizStatus.Loading;
      return state;
    },
    changeStatusToOk(state) {
      state.status = EditQuizStatus.Ok;
      state.error = null;
      return state;
    },
    changeStatusToError(state, action: PayloadAction<{ error: unknown }>) {
      const {
        payload: { error },
      } = action;
      state.status = EditQuizStatus.Error;
      state.error = error;
      return state;
    },
    changeStatusToSaving(state) {
      state.status = EditQuizStatus.Saving;
      return state;
    },
    toggleAnswerCorrect(
      state,
      action: PayloadAction<{ answerId: string | number }>
    ) {
      const { answerId } = action.payload;
      const answer = state.editAnswers[answerId];
      if (!answer) return state;

      state.editQuestion.isDirty = true;
      state.editAnswers[answerId].isCorrect =
        !state.editAnswers[answerId].isCorrect;

      return state;
    },
    changeAnswerText(
      state,
      action: PayloadAction<{ answerId: string | number; answerText: string }>
    ) {
      const { answerId, answerText } = action.payload;
      const answer = state.editAnswers[answerId];
      if (!answer) return state; // Do nothing if a non-existant id is passed.

      answer.text = answerText;
      state.editQuestion.isDirty = true;
      return state;
    },

    updateAnswerImage(
      state,
      action: PayloadAction<{ answerId: string | number; url: string }>
    ) {
      const { answerId, url } = action.payload;
      const answer = state.editAnswers[answerId];
      /**
       * Modify the image property if we have a new URL, or remove it if
       * no URL is provided (image cleared).
       */
      if (url) {
        answer.image = {
          id: new Date().getTime(),
          url,
          type: 'IM',
        };
      } else {
        delete answer.image;
      }
      state.editQuestion.isDirty = true;
      return state;
    },

    deleteAnswer(state, action: PayloadAction<{ answerId: string | number }>) {
      const { answerId } = action.payload;
      state.editQuestion.answers = state.editQuestion.answers.filter(
        (answer) => answer !== answerId
      );
      state.editQuestion.isDirty = true;
      state.editAnswers = omit(state.editAnswers, answerId as string);
    },
    addAnswerToQuestion(state, action: PayloadAction<{ order?: number }>) {
      const question = state.editQuestion;
      const { order } = action.payload;
      const isCorrect = question.type === QuestionTypes.FreeResponse;
      const numAnswers = question.answers.length;
      const answerId = `editing-${new Date().getTime()}-${numAnswers}`;
      const newAnswer: QuizAnswer = {
        createdById: -1,
        resources: [],
        text: '',
        questionId: question.questionId,
        id: answerId,
        isCorrect: isCorrect,
        order: order ?? numAnswers + 1,
      };

      state.editAnswers[answerId] = newAnswer;
      state.editQuestion.answers.push(answerId);
      state.editQuestion.isDirty = true;
      return state;
    },
    createBlankQuestion(
      state,
      action: PayloadAction<{
        questionType: QuestionTypes;
        position: number;
      }>
    ) {
      const { position, questionType } = action.payload;
      const questionId = `edit-${new Date().getTime()}`;
      const { question, answers } = createQuestion({
        questionType,
        order: position,
        questionId: questionId,
      });
      const newAnswers = answers.reduce((aggregator, value) => {
        const answerId = value.id;
        aggregator[answerId] = value;
        return aggregator;
      }, {} as { [key: string]: QuizAnswer });

      setTemporaryOrders(state, question.order);
      question.answers = answers.map((a) => a.id);
      state.editQuiz.questions.push(questionId);
      state.editAnswers = newAnswers;
      state.editQuestion = { ...question, isDirty: false };

      return state;
    },
    addQuestionToQuiz(
      state,
      action: PayloadAction<{ quizId: number | string; questionId: number }>
    ) {
      const {
        payload: { quizId, questionId },
      } = action;
      const quiz = state.quizzes[quizId];
      quiz.questions.push(questionId);

      const { editQuiz } = state;

      // If we are editing the quiz add those questions to the quiz
      if (editQuiz?.id === quizId) {
        editQuiz.questions.push(questionId);
      }

      return state;
    },

    updateQuestionText(state, action: PayloadAction<{ questionText: string }>) {
      const { questionText } = action.payload;
      const question = state.editQuestion;

      if (!question) return state;

      question.questionText = questionText;
      question.isDirty = true;

      return state;
    },

    updateGradingWeight(
      state,
      action: PayloadAction<{ gradingWeight: number }>
    ) {
      const { gradingWeight } = action.payload;
      const question = state.editQuestion;

      if (!question) return state;

      question.gradingWeight = gradingWeight;
      question.isDirty = true;

      return state;
    },

    updateQuestionImage(
      state,
      action: PayloadAction<{ url: string; id?: string }>
    ) {
      const { url } = action.payload;
      const question = state.editQuestion;

      if (!question) return state;
      if (question.questionImage && question.questionImage.url === url) {
        return state;
      }

      /**
       * Modify the questionImage property if we have a new URL, or remove it if
       * no URL is provided (image cleared).
       */
      if (url) {
        question.questionImage = {
          id: new Date().getTime(),
          url,
          type: 'IM',
        };
      } else {
        delete question.questionImage;
      }
      state.editQuestion.isDirty = true;
      return state;
    },

    updateQuestionExplanation(
      state,
      action: PayloadAction<{ explanationText: string }>
    ) {
      const { explanationText } = action.payload;
      const question = state.editQuestion;
      if (!question) return;
      question.explanation = explanationText;
      state.editQuestion.isDirty = true;

      return state;
    },

    updateQuestionExplanationImage(
      state,
      action: PayloadAction<{ url: string }>
    ) {
      const { url } = action.payload;
      const question = state.editQuestion;
      if (!question) return;

      /**
       * Modify the questionImage property if we have a new URL, or remove it if
       * no URL is provided (image cleared).
       */
      if (url) {
        question.explanationImage = {
          id: new Date().getTime(),
          url,
          type: 'IM',
        };
      } else {
        delete question.explanationImage;
      }
      state.editQuestion.isDirty = true;
      return state;
    },

    updateQuizTitle(state, action: PayloadAction<{ name: string }>) {
      const { name } = action.payload;
      const quiz = state.editQuiz;
      if (!quiz) return;
      quiz.name = name.trim();
      quiz.isDirty = true;
      return state;
    },
    toggleQuizSharing(state) {
      const quiz = state.editQuiz;
      quiz.isSharingEnabled = !quiz.isSharingEnabled;
      quiz.isDirty = true;
      return state;
    },

    updateQuizStandard(state, action: PayloadAction<QuizStandard>) {
      const quiz = state.editQuiz;
      quiz.standard = action.payload;
      return state;
    },

    setLegacyStandards(state, action: PayloadAction<LegacyStandard>) {
      state.standardModel = action.payload;
      return state;
    },

    updateQuestionVideoData(
      state,
      action: PayloadAction<{
        url: string;
        id?: string;
        start?: number;
        end?: number;
        duration?: number;
        provider?: 'youtube' | 'vimeo';
      }>
    ) {
      const payload = action.payload;
      const question = state.editQuestion;

      if (payload.url) {
        question.questionVideo = {
          type: MediaResourceTypes.Video,
          id: payload.id ?? 'edit', // typically set by the DB, edit id means it hasn't been saved. possibly consider using a flag?
          url: payload.url,
          start: payload.start ?? 0,
          end: payload.end ?? 0,
          duration: payload.duration,
          provider: payload.provider,
        };
      } else {
        question.questionVideo = null;
      }

      question.isDirty = true;
      return state;
    },
  },
});

const {
  setQuizzes,
  setQuizQuestions,
  setQuizAnswers,
  beginEditingQuestion,
  beginEditingQuiz,
  finishEditingQuestion,
  finishEditingQuiz,
  updateQuizQuestionOrder,
  copyQuestion,
  removeQuestion,
  addQuestionToQuiz,
  createBlankQuestion,
  toggleAnswerCorrect,
  changeAnswerText,
  updateAnswerImage,
  deleteAnswer,
  addAnswerToQuestion,
  changeStatusToError,
  changeStatusToLoading,
  changeStatusToOk,
  changeStatusToSaving,
  updateQuestionText,
  updateGradingWeight,
  updateQuestionImage,
  updateQuestionExplanation,
  updateQuestionExplanationImage,
  updateQuizTitle,
  toggleQuizSharing,
  updateQuizStandard,
  setLegacyStandards,
  removeQuiz,
  refreshEditingQuiz,
  updateQuestionVideoData,
} = quizSlice.actions;

export {
  setQuizzes,
  setQuizQuestions,
  setQuizAnswers,
  beginEditingQuestion,
  beginEditingQuiz,
  finishEditingQuestion,
  finishEditingQuiz,
  updateQuizQuestionOrder,
  copyQuestion,
  removeQuestion,
  addQuestionToQuiz,
  createBlankQuestion,
  toggleAnswerCorrect,
  changeAnswerText,
  updateAnswerImage,
  deleteAnswer,
  addAnswerToQuestion,
  changeStatusToError,
  changeStatusToLoading,
  changeStatusToOk,
  changeStatusToSaving,
  updateQuestionText,
  updateGradingWeight,
  updateQuestionImage,
  updateQuestionExplanation,
  updateQuestionExplanationImage,
  updateQuizTitle,
  toggleQuizSharing,
  updateQuizStandard,
  setLegacyStandards,
  removeQuiz,
  refreshEditingQuiz,
  updateQuestionVideoData,
};

export default quizSlice.reducer;
