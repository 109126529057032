import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconGoogleDriveSvg } from './IconGoogleDriveSvg';

export type IconGoogleDriveProps = UseIconProps;

export function IconGoogleDrive(props: IconGoogleDriveProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconGoogleDriveSvg} {...iconProps} />;
}
