import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BetaFlags } from '@showbie-socrative/socrative-utils';

import { PaywallTouch } from '~teacher/components/PaywallDialog';

type CurrentRoomActivity = {
  activityInstanceId: number;
  isLive: boolean;
};
export type TeacherSliceState = {
  isTeacher: boolean;
  teacherId: number;
  currentRoomId: number;
  isCurrentRoomShowbie: boolean;
  betaFlags: BetaFlags | Record<string, never> | null;
  isPaywallOpen: boolean;
  paywallTouch: PaywallTouch;
  isRequestInProgress: boolean;
  currentRoomActivity: CurrentRoomActivity;
};

export const initialState = (): TeacherSliceState => ({
  isTeacher: true,
  teacherId: 0,
  currentRoomId: 0,
  isCurrentRoomShowbie: false,
  betaFlags: null,
  isPaywallOpen: false,
  paywallTouch: [null],
  isRequestInProgress: false,
  currentRoomActivity: { activityInstanceId: 0, isLive: false },
});

const teacherSlice = createSlice({
  name: 'teacherData',
  initialState: initialState(),
  reducers: {
    setIsTeacher(state, action: PayloadAction<{ isTeacher: boolean }>) {
      const { isTeacher } = action.payload;
      state.isTeacher = isTeacher;
      return state;
    },
    setTeacherId(state, action: PayloadAction<{ teacherId: number }>) {
      const { teacherId } = action.payload;
      state.teacherId = teacherId;
      return state;
    },
    setBetaFlags(state, action: PayloadAction<{ betaFlags: any }>) {
      const { betaFlags } = action.payload;
      state.betaFlags = betaFlags;
      return state;
    },
    currentRoomChanged(
      state,
      action: PayloadAction<{ roomId: number; isShowbieRoom: boolean }>
    ) {
      const { roomId, isShowbieRoom } = action.payload;
      state.isCurrentRoomShowbie = isShowbieRoom;
      state.currentRoomId = roomId;
      return state;
    },
    currentRoomActivityChanged(
      state,
      action: PayloadAction<{ activityInstanceId: number; isLive: boolean }>
    ) {
      state.currentRoomActivity = action.payload;
    },
    openPaywall(state, action: PayloadAction<{ paywallTouch: PaywallTouch }>) {
      state.isPaywallOpen = true;
      state.paywallTouch = action.payload?.paywallTouch;
      return state;
    },
    closePaywall(state) {
      state.isPaywallOpen = false;
      return state;
    },
    requestSent(state) {
      state.isRequestInProgress = true;
      return state;
    },
    requestFinished(state) {
      state.isRequestInProgress = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('@@INIT', () => {
      return initialState();
    });
  },
});

const {
  setIsTeacher,
  setTeacherId,
  currentRoomChanged,
  currentRoomActivityChanged,
  setBetaFlags,
  openPaywall,
  closePaywall,
  requestSent,
  requestFinished,
} = teacherSlice.actions;

export {
  setIsTeacher,
  setTeacherId,
  currentRoomChanged,
  currentRoomActivityChanged,
  setBetaFlags,
  openPaywall,
  closePaywall,
  requestSent,
  requestFinished,
};

export default teacherSlice.reducer;
