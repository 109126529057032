import * as React from 'react';

import { SVGProps } from '../../types';

export const IconDownloadSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.25 20.45a.75.75 0 01.102 1.493l-.102.007H4.75a.75.75 0 01-.102-1.493l.102-.007h14.5zM14.25 3a1.75 1.75 0 011.744 1.606L16 4.75V10h3.25c.59 0 .922.632.661 1.107l-.056.087-.066.077-7.25 7.5a.75.75 0 01-.993.076l-.085-.076-7.25-7.5a.75.75 0 01.437-1.265L4.75 10H8V4.75c0-.867.63-1.587 1.458-1.726l.148-.018L9.75 3h4.5zm0 1.5h-4.5a.25.25 0 00-.243.193L9.5 4.75v6a.75.75 0 01-.648.743l-.102.007H6.519L12 17.17l5.48-5.67h-2.23a.75.75 0 01-.723-.55l-.02-.098-.007-.102v-6a.25.25 0 00-.193-.243L14.25 4.5z" />
  </svg>
);
