const unescapabledChars: { [htmlEntity: string]: string } = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#039;': `'`,
  '&#96;': '`',
  '&#33;': '!',
  '&#64;': '@',
  '&#37;': '%',
  '&#61;': '=',
  '&#125;': '}',
  '&#123;': '{',
};

/**
 * This is copied from the old Utils.js
 * @param content
 */
const unescapeLegacyHTML = (content: string) =>
  content
    ? Object.entries(unescapabledChars).reduce(
        (aggregator, [htmlEntity, stringValue]) =>
          aggregator.replace(new RegExp(htmlEntity, 'g'), stringValue),
        content
      )
    : content;

export default unescapeLegacyHTML;
