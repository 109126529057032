import * as React from 'react';
import * as ReactDOM from 'react-dom';

import dots from '../buttons/Dots';
import PopupModel from './PopupModel';
import PopupView from './PopupView';

const utils = require('../../../../shared/Utils');

let model = null;

class PopupController {
  /**
   * Supported Properties:
   *     message          {String|React.DOM}  the message to show in the pop up. this may also be a React.DOM component
   *     title            {String}            the title that will appear in the pop up header
   *     buttonText       {String}            the text for the button, the default is OK
   *     cancelText       {String}            the text for the Cancel button found in the footer
   *     overrideButtons  {Boolean}           bool to not display (override) the main buttons
   *     buttonClicked    {Function}          the method called when the main button is clicked
   *     cancelClicked    {Function}          the method called when the pop up is closed (either by clicking on the X or the background)
   *     focusElement     {String}            the ID of the input that will be focused after the pop up is rendered (used in the registration pop up, for the phone input)
   *     isModal          {Boolean}           flag to mark the popup as modal, a button must be clicked to close it (X is hidden, background click will not close)
   */
  render(props) {
    model = new PopupModel();
    model.buttonClicked = props.buttonClicked;
    model.cancelClicked = props.cancelClicked;
    model.focusElement = props.focusElement;
    model.isModal = props.isModal;
    model.buttonDisabled = props.buttonDisabled;

    const popupContainer = document.createElement('div');
    popupContainer.id = 'pop-up-container';
    document.body.appendChild(popupContainer);

    ReactDOM.render(
      React.createElement(PopupView, {
        title: props.title,
        message: props.message,
        buttonText: props.buttonText,
        cancelText: props.cancelText,
        controller: controller,
        buttonDisabled: props.buttonDisabled,
        overrideButtons: props.overrideButtons,
        isModal: props.isModal,
      }),
      popupContainer,
      () => {
        utils.blurPage(document.getElementById('pop-up-box'));
        document.addEventListener('keydown', controller.onKeyDown);
      }
    );
  }

  onKeyDown(event) {
    if (!model.isModal && (event.key === 'Escape' || event.key === 'Esc')) {
      // If the escape key was pressed, cancel the popup.
      controller.cancelClicked(event);
    }
  }

  cancelClicked(event, closeXClicked = false) {
    controller.onClickOrEscape(event);

    if (model.cancelClicked) {
      model.cancelClicked(closeXClicked);
    }
  }

  buttonClicked(event) {
    controller.onClickOrEscape(event);

    if (model.buttonClicked) {
      model.buttonClicked();
    }
  }

  onClickOrEscape(event) {
    document.removeEventListener('keydown', controller.onKeyDown);
    controller.closePopup(event);
  }

  closePopup(event) {
    if (event) {
      event.stopPropagation(); // Stop duplicate click events that might fire from buttons and the background.
    }

    const popupBackground = document.getElementById('pop-up-container');

    if (popupBackground) {
      ReactDOM.unmountComponentAtNode(popupBackground);
      popupBackground.parentNode.removeChild(popupBackground);
    }

    utils.unblurPage();

    if (model && model.focusElement) {
      const focusElement = document.getElementById(model.focusElement);

      if (focusElement) {
        focusElement.focus();
      }
    }

    dots.stop();
  }
}

const controller = new PopupController();
export default controller;
