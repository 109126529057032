import * as React from 'react';

import { SVGProps } from '../../types';

export const IconFolderAddSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M19.25 15.25a.75.75 0 01.743.648L20 16l-.001 2.5H22.5a.75.75 0 01.102 1.493L22.5 20h-2.501L20 22.5a.75.75 0 01-1.493.102L18.5 22.5l-.001-2.5H16a.75.75 0 01-.102-1.493L16 18.5h2.499L18.5 16a.75.75 0 01.75-.75zm-10.202-11c1.207 0 2.137.803 2.412 1.884l.036.164.221.8.024.103c.017.087.022.104.084.107h6.044c1.126 0 2.048.875 2.126 1.98l.005.153V12a.75.75 0 01-1.493.102L18.5 12V9.441a.632.632 0 00-.538-.625l-.093-.007h-6c-.774 0-1.364-.453-1.56-1.149l-.045-.189-.227-.832c-.096-.5-.417-.83-.864-.882l-.125-.007H3.762c-.522 0-.951.395-1.006.904l-.006.11v11.104c0 .317.234.58.538.625l.093.007H12a.75.75 0 01.102 1.493L12 20H3.381a2.132 2.132 0 01-2.126-1.98l-.005-.152V6.765a2.513 2.513 0 012.347-2.51l.165-.005h5.286z" />
  </svg>
);
