import * as React from 'react';

import { SVGProps } from '../../types';

export const IconLockSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M13 8V4.5C13 2.84315 11.6569 1.5 10 1.5H6C4.34315 1.5 3 2.84315 3 4.5V8"
      stroke="#ABB4BD"
      fill="white"
      strokeWidth="1.5"
    />
    <rect
      x="0.75"
      y="7.75"
      width="14.5"
      height="10.5"
      rx="2.25"
      fill="white"
      stroke="#ABB4BD"
      strokeWidth="1.5"
    />
    <rect x="7" y="10.5" width="2" height="3.5" rx="1" fill="#ABB4BD" />
  </svg>
);
