import * as React from 'react';

import { SVGProps } from '../../types';

export const IconCopyToSVG = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M20 3.5H10C9.72386 3.5 9.5 3.72386 9.5 4V11H8V4C8 2.89543 8.89543 2 10 2H20C21.1046 2 22 2.89543 22 4V14C22 15.1046 21.1046 16 20 16H13V14.5H20C20.2761 14.5 20.5 14.2761 20.5 14V4C20.5 3.72386 20.2761 3.5 20 3.5Z" />
    <path d="M12.75 6C12.3358 6 12 6.33579 12 6.75C12 7.16421 12.3358 7.5 12.75 7.5H15.4393L8.46967 14.4697C8.17678 14.7626 8.17678 15.2374 8.46967 15.5303C8.76256 15.8232 9.23744 15.8232 9.53033 15.5303L16.5 8.56066V11.25C16.5 11.6642 16.8358 12 17.25 12C17.6642 12 18 11.6642 18 11.25V6.75C18 6.33579 17.6642 6 17.25 6H12.75Z" />
    <path d="M2 20C2 21.1046 2.89543 22 4 22H14C15.1046 22 16 21.1046 16 20V17H14.5V20C14.5 20.2761 14.2761 20.5 14 20.5H4C3.72386 20.5 3.5 20.2761 3.5 20V10C3.5 9.72386 3.72386 9.5 4 9.5H7V8H4C2.89543 8 2 8.89543 2 10V20Z" />
  </svg>
);
