export enum ReportActions {
  REQUEST_STUDENTS = 'REQUEST_STUDENTS',
  REQUEST_SENT = 'REQUEST_SENT',
  REQUEST_FINISHED = 'REQUEST_FINISHED',
  SELECT_ALL_STUDENTS = 'SELECT_ALL_STUDENTS',
  DESELECT_ALL_STUDENTS = 'DESELECT_ALL_STUDENTS',
  TOGGLE_STUDENT = 'TOGGLE_STUDENT',
}

export enum ReportsModalScreen {
  OPTIONS_VIEW = 'OPTIONS_VIEW',
  PAYWALL_VIEW = 'PAYWALL_VIEW',
  FEEDBACK_VIEW = 'FEEDBACK_VIEW',
  EMAIL_STUDENTS_VIEW = 'EMAIL_STUDENTS_VIEW',
}

export enum ReportRecipientsScreen {
  REPORT_OPTIONS_VIEW = 'REPORT_OPTIONS_VIEW',
  EMAIL_STUDENTS_VIEW = 'EMAIL_STUDENTS_VIEW',
  EMAIL_SUCCESS_VIEW = 'EMAIL_SUCCESS_VIEW',
  ERROR_FEEDBACK_VIEW = 'ERROR_FEEDBACK_VIEW',
  ROSTER_FETCH_ERROR = 'ROSTER_FETCH_ERROR ',
}

export enum ReportDeliveryType {
  DOWNLOAD_REPORT = 'dl',
  EMAIL_REPORT = 'em',
  GOOGLE_DRIVE = 'go',
}
