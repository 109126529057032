import * as React from 'react';

import { Box } from '~shared/components/Box';

import { UseIconProps, useIcon } from '../../useIcon';
import { IconMoveSvg } from './IconMoveSvg';

export type IconMoveProps = UseIconProps;

export function IconMove(props: IconMoveProps) {
  const iconProps = useIcon(props);

  return <Box tagName={IconMoveSvg} {...iconProps} />;
}
