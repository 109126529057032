import { QuestionTypes } from '@showbie-socrative/socrative-utils';

import { QuizAnswer, QuizQuestion } from './quizzes';

function createQuestionByType({
  answers,
  order,
  questionType,
  questionId,
}: {
  answers: { id: string | number }[];
  order: number;
  questionType: QuestionTypes;
  questionId: string;
}) {
  return {
    answers: answers.map((a) => a.id),
    order,
    createdById: null,
    createdDate: null,
    explanation: '',
    type: questionType,
    resources: [],
    gradingWeight: 1,
    questionId,
    questionText: '',
  };
}

function createTrueFalse({
  questionId,
  order,
}: {
  questionId: string;
  order: number;
}) {
  const idGenerator = createIdGenerator(() => `${new Date().getTime()}`);
  const answers: QuizAnswer[] = [
    {
      questionId: questionId,
      text: 'True',
      createdById: -1,
      id: idGenerator(),
      isCorrect: true,
      resources: [],
      order: 1,
    },
    {
      questionId: questionId,
      text: 'False',
      createdById: -1,
      id: idGenerator(),
      isCorrect: false,
      resources: [],
      order: 2,
    },
  ];
  const question = createQuestionByType({
    answers,
    order,
    questionType: QuestionTypes.TrueFalse,
    questionId,
  });
  return { question, answers };
}

const createIdGenerator = (
  uniqueGenerator: () => string,
  prefix = 'editing'
) => {
  let generation = 0;
  return () => {
    generation += 1;
    const uniqueness = uniqueGenerator();
    return `${prefix}-${uniqueness}-${generation}`;
  };
};

function createMultipleChoice({
  questionId,
  order,
}: {
  questionId: string;
  order: number;
}) {
  const idGenerator = createIdGenerator(() => `${new Date().getTime()}`);
  const answers: QuizAnswer[] = Array.from({ length: 4 }, (value, index) => ({
    questionId: questionId,
    text: '',
    createdById: -1,
    id: idGenerator(),
    isCorrect: false,
    resources: [],
    order: index + 1,
  }));

  const question = createQuestionByType({
    answers,
    order,
    questionType: QuestionTypes.MultipleChoice,
    questionId,
  });
  return { question, answers };
}
function createFreeResponseQuestion({
  questionId,
  order,
}: {
  questionId: string;
  order: number;
}) {
  const idGenerator = createIdGenerator(() => `${new Date().getTime()}`);
  const answers: QuizAnswer[] = Array.from({ length: 5 }, (value, index) => ({
    questionId: questionId,
    text: '',
    createdById: -1,
    id: idGenerator(),
    isCorrect: true,
    resources: [],
    order: index + 1,
  }));
  const question = createQuestionByType({
    answers,
    order,
    questionType: QuestionTypes.FreeResponse,
    questionId,
  });
  return { question, answers };
}

function createMatchingQuestion({
  questionId,
  order,
}: {
  questionId: string;
  order: number;
}) {
  const idGenerator = createIdGenerator(() => `${new Date().getTime()}`);
  const answers: QuizAnswer[] = Array.from({ length: 5 }, (value, index) => ({
    questionId: questionId,
    text: '',
    createdById: -1,
    id: idGenerator(),
    isCorrect: null,
    resources: [],
    order: index + 1,
  })).concat(
    Array.from({ length: 5 }, (value, index) => ({
      questionId: questionId,
      text: '',
      createdById: -1,
      id: idGenerator(),
      isCorrect: null,
      resources: [],
      order: index + 1001,
    }))
  );
  const question = createQuestionByType({
    answers,
    order,
    questionType: QuestionTypes.Matching,
    questionId,
  });
  return { question, answers };
}

// TODO: Write unit tests...

export const createQuestion = ({
  questionType,
  order,
  questionId,
}: {
  questionType: QuestionTypes;
  order: number;
  questionId: string;
}): { question: QuizQuestion; answers: QuizAnswer[] } => {
  switch (questionType) {
    case QuestionTypes.FreeResponse: {
      return createFreeResponseQuestion({ questionId, order });
    }
    case QuestionTypes.MultipleChoice: {
      return createMultipleChoice({ questionId, order });
    }
    case QuestionTypes.TrueFalse: {
      return createTrueFalse({ questionId, order });
    }
    case QuestionTypes.Matching: {
      return createMatchingQuestion({ questionId, order });
    }

    default:
      invalidQuestionType(questionType);
  }
};

function invalidQuestionType(x: never): never {
  throw new Error(`invalid question type ${x}`);
}
