import { OptionalTitle } from './types';

export type UseIconProps = {
  size?: 'standard' | 'large' | 'micro';
  className?: string;
  'data-testid'?: string;
} & OptionalTitle;

export const useIcon = ({ size, ...props }: UseIconProps) => {
  const a11yProps = props.title ? { role: 'img' } : { 'aria-hidden': true };

  switch (size) {
    case 'large':
      return {
        width: 36,
        height: 36,
        ...props,
        ...a11yProps,
      };

    case 'micro':
      return {
        width: 18,
        height: 18,
        ...props,
        ...a11yProps,
      };

    case 'standard':
    default:
      return {
        width: 24,
        height: 24,
        ...props,
        ...a11yProps,
      };
  }
};
