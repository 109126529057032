import * as React from 'react';

import { SVGProps } from '../../types';

export const IconTrashSvg = ({ title, titleId, ...props }: SVGProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    focusable="false"
    fill="currentColor"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M14 2a2 2 0 012 2v1h4.5a.75.75 0 01.102 1.493L20.5 6.5H19V19a2 2 0 01-2 2H7a2 2 0 01-2-2V6.5H3.5a.75.75 0 01-.102-1.493L3.5 5H8V4a2 2 0 012-2h4zm3.5 4.5h-11V19a.5.5 0 00.41.492L7 19.5h10a.5.5 0 00.492-.41L17.5 19V6.5zM9 8.25a.75.75 0 01.743.648L9.75 9v8a.75.75 0 01-1.493.102L8.25 17V9A.75.75 0 019 8.25zm3 0a.75.75 0 01.743.648L12.75 9v8a.75.75 0 01-1.493.102L11.25 17V9a.75.75 0 01.75-.75zm3 0a.75.75 0 01.743.648L15.75 9v8a.75.75 0 01-1.493.102L14.25 17V9a.75.75 0 01.75-.75zM14 3.5h-4a.5.5 0 00-.492.41L9.5 4v1h5V4a.5.5 0 00-.41-.492L14 3.5z" />
  </svg>
);
