import * as React from 'react';

import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';

import CloseX from '../../../shared/components/buttons/CloseX';

interface Props {
  overrideButtons: boolean;
  buttonDisabled: () => boolean;
  controller: any;
  buttonText: string;
  isModal: boolean;
  message: string;
  title: string;
  cancelText: string;
}

export default class PopupView extends React.Component<Props> {
  createFooterChildren() {
    if (this.props.overrideButtons) {
      return [];
    }

    const footerChildren = [
      <button
        key="footerChildren-1"
        className="button button-primary button-large"
        disabled={
          this.props.buttonDisabled ? this.props.buttonDisabled() : false
        }
        onClick={this.props.controller.buttonClicked}
      >
        {this.props.buttonText ? this.props.buttonText : translate('OK')}
      </button>,
    ];

    if (this.props.cancelText) {
      footerChildren.push(
        <button
          key="footerChildren-2"
          className="button button-primary-common button-large"
          onClick={this.props.controller.cancelClicked}
        >
          {this.props.cancelText}
        </button>
      );
    }

    return footerChildren;
  }

  render() {
    const c = this.props.controller;

    const cancelButton = (
      <a
        className="cancel-button"
        style={{
          display: 'block',
        }}
        title={translate('Close')}
        onClick={(event) => {
          c.cancelClicked(event, true);
        }}
      >
        <CloseX color="#ccc" />
      </a>
    );

    return (
      <div
        id="pop-up-bg"
        className="modal open"
        onClick={this.props.isModal ? () => {} : c.cancelClicked}
      >
        <div
          id="pop-up-box"
          className="modal-dialog"
          onClick={function(event) {
            event.stopPropagation(); // Make sure clicks on the dialog do not bubble up to the background.
          }}
        >
          <div className="modal-header">
            <span className="popup-title">{this.props.title}</span>
            {cancelButton}
          </div>
          <div
            style={{
              hyphens: 'auto',
              overflowWrap: 'break-word',
            }}
            id="pop-up-message"
          >
            {this.props.message}{' '}
          </div>
          <div className="modal-footer">{this.createFooterChildren()}</div>
        </div>
      </div>
    );
  }
}
